import { __decorate } from "tslib";
import { Component, Vue, Ref } from 'vue-property-decorator';
import { LogService } from '@/sevices';
import { CTable } from '@cloudpivot-hermes/common-components';
let LoginLog = class LoginLog extends Vue {
    constructor() {
        super(...arguments);
        this.isShowFilter = false;
        this.sourceType = {
            0: '工作台/运营后台',
            1: '开发平台/PASS平台',
            2: '开发平台（钉钉）',
        };
        /**
         * table列定义
         */
        this.tableColumns = [
            {
                width: 60,
                dataIndex: 'index',
                key: 'index',
                align: 'center',
                slots: { title: 'indexTitle', name: '序号' },
                scopedSlots: { customRender: 'index' },
            },
            {
                dataIndex: 'userName',
                key: 'userName',
                ellipsis: true,
                slots: { title: 'userNameTitle', name: '用户' },
            },
            {
                dataIndex: 'operationTime',
                key: 'operationTime',
                ellipsis: true,
                slots: { title: 'loginTimeTitle', name: '登录时间' },
            },
            {
                dataIndex: 'ip',
                key: 'ip',
                ellipsis: true,
                slots: { title: 'ipTitle', name: '登录IP' },
            },
            {
                dataIndex: 'source',
                key: 'source',
                ellipsis: true,
                slots: { title: 'sourceTitle', name: '登录来源' },
            },
            {
                dataIndex: 'device',
                key: 'device',
                ellipsis: true,
                slots: { title: 'deviceTitle', name: '设备类型' },
            },
            {
                dataIndex: 'browser',
                key: 'browser',
                ellipsis: true,
                slots: { title: 'browserTitle', name: '浏览器' },
            },
        ];
        this.searchParams = {};
    }
    showFilter() {
        this.isShowFilter = !this.isShowFilter;
    }
    closeFilter() {
        this.isShowFilter = false;
    }
    /**
     * 搜索
     */
    search(searchParams) {
        this.searchParams = searchParams;
        this.cTable.pagination.current = 1;
        this.showFilter();
        this.cTable.getTableList();
    }
    /**
     * 获取列表数据接口
     */
    async getList(params) {
        const p = {
            ...params,
            ...this.searchParams,
            page: params.page - 1,
        };
        const res = await LogService.getLoginLogList(p);
        if (res.success) {
            const data = res.data || [];
            data.forEach((item) => {
                item.source = this.sourceType[item.source];
            });
        }
        return res;
    }
};
__decorate([
    Ref()
], LoginLog.prototype, "cTable", void 0);
LoginLog = __decorate([
    Component({
        name: 'LoginLog',
        components: {
            CTable,
            FilterDrawer: () => import('@/components/sys-manage/log-info/filter-drawer'),
            FilterLoginLog: () => import('@/components/sys-manage/log-info/filter-drawer/filter-login-log.vue'),
        },
    })
], LoginLog);
export default LoginLog;
